import React from 'react'
import dummyData from '../data/DummyData'

function CekHargaPage() {
    return (
        <>
            <div className="container">
                <div className="card-header row justify-content-between border-0 mt-4 mb-3 shadow-lg p-3 bg-secondary rounded">
                    <div className="mb-10">
                        <h1>Kasir Hardjo</h1>
                    </div>
                    <div className="mb-10">
                        <label className="form-label">Cari Berdasarkan Nama Produk atau Barcode Scan</label>
                        <input
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <a href="#" className="btn btn-primary mb-10"><i className="fas fa-search "></i>Search</a>

                    <div className="container-fluid">
                        <table className="table table-row-dashed table-row-gray-300 gy-7">
                            <thead>
                            <tr className="fw-bolder fs-6 text-gray-800 min-w-30px text-center">
                                    <th>NO</th>
                                    <th className='min-w-300px'>Nama Barang</th>
                                    <th className='min-w-300px'>Kode</th>
                                    <th className='min-w-150px'>Harga</th>

                                </tr>
                            </thead>
                            <tbody>
                                {dummyData.map((item, index) => (
                                    <tr key={index} className=" text-center">
                                        <td>{item.no}</td>
                                        <td>{item.namaProduk}</td>
                                        <td>{item.kode}</td>
                                        <td>
                                            <input style={{ border: '1px solid #E3E6EF', padding: '2% 5%' }} value={item.jumlah2}
                                                readOnly />
                                            <input style={{ border: '1px solid #E3E6EF', padding: '2% 5%' }} value={item.harga2}
                                                readOnly />
                                            <br />
                                            <input style={{ border: '1px solid #E3E6EF', padding: '2% 5%' }} value={item.jumlah}
                                                readOnly />
                                            <input style={{ border: '1px solid #E3E6EF', padding: '2% 5%'}} value={item.harga}
                                                readOnly />
                                        </td>

                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CekHargaPage