import React from 'react'
import StokProductPage from '../../modules/admintbk/StokProductPage'
import ProductPage from '../../modules/cassier/ProductPage'

const StokProduk = () => {
  return (
    <StokProductPage></StokProductPage>
  )
}

export default StokProduk