import React from 'react'
import DashboardPage from '../../modules/admintbk/DashboardPage'
import ProductPage from '../../modules/cassier/ProductPage'
import AllProdukPage from '../../modules/admintbk/AllProdukPage'

const AllProduk = () => {
    return (
        <AllProdukPage></AllProdukPage>
    )
}

export default AllProduk