import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { KTCard } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom';


interface Product {
    id: number;
    kode: string;
    name: string;
    tanggal: string,
    jumlah: string,
    diskon: string,
    price: number;
}

const StokProductPage = () => {
    const [hargaJualFields, setHargaJualFields] = useState([{ harga: '', jumlah: '', jenis: '' }]);

    const handleTambahHarga = () => {
        setHargaJualFields([...hargaJualFields, { harga: '', jumlah: '', jenis: '' }]);
    };
    const handleJenisChange = (value: string, index: number) => {
        const updatedFields = [...hargaJualFields];
        updatedFields[index].jenis = value;
        setHargaJualFields(updatedFields);
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null | any>([
        { id: 1, name: 'Product 1', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 19000 },
        { id: 2, name: 'Product 2', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 29000 },
    ]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleAddStock = () => {
        setShowModal(false);
    };
    const handleSelectProduct = (product: Product) => {
        let newProduct = product
        setSelectedProduct([...selectedProduct, newProduct]);
        setShowModal(false);
    };
    const data = [
        { harga: "Rp. 5.000", jumlah: "1 pcs" },
        { harga: "Rp. 500.000", jumlah: "1 box" }

    ];

    return (
        <>
            <KTCard>
                <div className='card pt-6'>
                    <div className=' card-header border-0'>
                        <div className='row gap-4 fw-bold'>
                            <div className='col-3'>
                                <label className='mb-2'>Nama Product</label>
                                <input placeholder='Enter your product name' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                                {/* <div>
                                    <button
                                        type="button" className="btn btn-text" data-bs-toggle="modal" data-bs-target={"#product"} data-bs-whatever="@mdo"
                                    >
                                        open Modal
                                    </button>
                                </div> */}
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Kategori</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="WAIT_PICKUP"></option>
                                    <option value="PICKED_UP">Agar-Agar</option>
                                    <option value="ON_DELIVERY">Air Minum</option>
                                    <option value="RECEIVED">Alat</option>
                                    <option value="CANCELED">Bahan</option>
                                </select>
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Kode</label>
                                <input placeholder='Enter your product name' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>

                            <div className='col-3'>
                                <label className='mb-2'>Arrange by</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Input Date</option>
                                    <option value="ON_DELIVERY">Kategori</option>
                                    <option value="RECEIVED">Kode</option>
                                    <option value="CANCELED">Nama Product</option>
                                </select>
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Order</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Ascending</option>
                                    <option value="ON_DELIVERY">Descending</option>
                                </select>
                            </div>
                            <div className='col-3 mt-8'>
                                <Button>Search</Button>
                            </div>
                            {/* <div className='col-3 mt-8' data-bs-toggle="modal" data-bs-target="#ModalLabel" data-bs-whatever="@mdo">
                                <Button onClick={handleOpenModal}>Tambah Stok</Button>
                            </div> */}
                        </div>
                    </div>
                    {/* tabel */}
                    <div className='card-body'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th align='center' className='min-w-30px'>
                                            NO
                                        </th>
                                        <th className='min-w-150px'>Nama Produk</th>
                                        <th className='min-w-140px'>Kategori</th>
                                        <th className='min-w-120px'>Kode</th>
                                        <th className='min-w-120px'>Jumlah</th>
                                        <th className='min-w-120px'>Action</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {Array.from(Array(10).keys()).map((val: number) => (
                                        <tr key={val}>
                                            <td align='center'>
                                                {val + 1}
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-text" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    FRISIAN FLAG SKM POUCH CHOCOLATE 280GR
                                                </a></button>

                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    Susu
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    8992753722747
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    20
                                                </div>


                                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        Web, UI/UX Design
                                    </span> */}
                                            </td>
                                            <td>
                                                <button className="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#editModalLabel" data-bs-whatever="@mdo">Edit</button>
                                                <span>  </span>
                                                <Link to="/StokProduk/history">
                                                    <button className="btn btn-danger btn-sm">History</button>
                                                </Link>

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* pagination */}
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div aria-label="..." id='kt_table_users_paginate'>
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* end::Table container */}
                        {/* modal */}
                        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Detail Product</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="mb-3">
                                                <label className="col-form-label">Nama Product:</label>
                                                <input type="text" value={"FRISIAN FLAG SKM POUCH CHOCOLATE 280GR"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Kategori:</label>
                                                <input type="text" value={"Susu"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Kode:</label>
                                                <input type="text" value={"8992753722747"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Jumlah:</label>
                                                <input type="text" value={"20"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Harga Jual:</label>
                                                {data.map((item, index) => (
                                                    <div key={index} className="d-flex mb-3">
                                                        <input type="text" value={item.harga}
                                                            disabled className="form-control me-2" id="recipient-name" />
                                                        <input type="text" value={item.jumlah} disabled className="form-control" id="recipient-name" />
                                                    </div>
                                                ))}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal fade" id="ModalLabel" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel" >Tambah Stok</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="col-form-label">Nama Product:</label>
                                            <input type="text" placeholder="Nama Produk" className="form-control" id="product-name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Kategori:</label>
                                            <input type="text" placeholder="Kategori" className="form-control" id="category" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Kode:</label>
                                            <input type="text" placeholder="Kode" className="form-control" id="code" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Jumlah:</label>
                                            <input type="text" placeholder="Jumlah" className="form-control" id="quantity" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Harga Jual:</label>
                                            {hargaJualFields.map((field, index) => (
                                                <div key={index} className="d-flex mb-3">
                                                    <input type="text" placeholder="Harga Jual" className="form-control me-2" value={field.harga} onChange={(e) => {
                                                        const updatedFields = [...hargaJualFields];
                                                        updatedFields[index].harga = e.target.value;
                                                        setHargaJualFields(updatedFields);
                                                    }} />
                                                    <input type="text" placeholder="Jumlah" className="form-control me-2" value={field.jumlah} onChange={(e) => {
                                                        const updatedFields = [...hargaJualFields];
                                                        updatedFields[index].jumlah = e.target.value;
                                                        setHargaJualFields(updatedFields);
                                                    }} />
                                                    <input type="text" placeholder="Jenis" className="form-control me-2" value={field.jenis} onChange={(e) => {
                                                        handleJenisChange(e.target.value, index);
                                                    }} />
                                                </div>
                                            ))}
                                            <button type="button" className="btn btn-primary" onClick={handleTambahHarga}>
                                                Tambah Harga Jual
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleAddStock}>Tambah Stok</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="modal fade" id="editModalLabel" aria-labelledby="editModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="editModalLabel">Edit Product</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="col-form-label">Nama Product:</label>
                                            <input type="text" placeholder="Nama Produk" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Kategori:</label>
                                            <input type="text" placeholder="Kategori" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Kode:</label>
                                            <input type="text" placeholder="Kode" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Jumlah:</label>
                                            <input type="text" placeholder="Jumlah" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Harga Jual:</label>
                                            {hargaJualFields.map((field, index) => (
                                                <div key={index} className="d-flex mb-3">
                                                    <input type="text" placeholder="Harga Jual" className="form-control me-2" value={field.harga} onChange={(e) => {
                                                        const updatedFields = [...hargaJualFields];
                                                        updatedFields[index].harga = e.target.value;
                                                        setHargaJualFields(updatedFields);
                                                    }} />
                                                    <input type="text" placeholder="Jumlah" className="form-control me-2" value={field.jumlah} onChange={(e) => {
                                                        const updatedFields = [...hargaJualFields];
                                                        updatedFields[index].jumlah = e.target.value;
                                                        setHargaJualFields(updatedFields);
                                                    }} />
                                                    <input type="text" placeholder="Jenis" className="form-control me-2" value={field.jenis} onChange={(e) => {
                                                        handleJenisChange(e.target.value, index);
                                                    }} />
                                                </div>
                                            ))}
                                            <button type="button" className="btn btn-primary" onClick={handleTambahHarga}>
                                                Tambah Harga Jual
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" >Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="deleteModal" aria-labelledby="deleteModal" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Hapus Produk</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Anda ingin menghapus Stok Ini ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Hapus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </KTCard>
        </>
    )
}

export default StokProductPage