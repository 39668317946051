import React, { useState, useEffect } from 'react';
import { ChartsWidget1, ChartsWidget2 } from '../../../_metronic/partials/widgets';
// import SearchProductModal from './components/SearchProductModal';
import { convertIDR, formatCurrency } from '../../functions/global';
import Pembayaran from '../apps/components/Pembayaran';
import TahanTransaksi from '../apps/components/TahanTransaksi';
import { showDashboard } from '../../functions/global/api';


interface DashboardData {
    total_transaction: number;
    total_request_retur: number;
    total_retur: number;
}

const DashboardPage = () => {
    const userJSON = sessionStorage.getItem('user');
    const user = userJSON ? JSON.parse(userJSON) : null;
    
    const [dashboard, setDashboard] = useState<DashboardData | null>(null);
    const showData = () => {

        showDashboard()
            .then(data => {
                setDashboard(data);
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });
    };

    useEffect(() => {
        showData();
    }, []);


    return (
        <div className="container">
            <div>
                <section className="content-header content-header-custom">
                    <h1>Selamat datang {user?.name}</h1>
                </section>
            </div>

            <div className="row g-4 my-10">
                <div className="col-lg-4 col-md-6">
                    <div className="card bg-success">
                        <div className="card-body text-white">
                            <h3 className="text-white">
                                <strong>{dashboard && dashboard.total_transaction} Produk Terjual</strong>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card bg-warning">
                        <div className="card-body text-white">
                            <h3 className="text-white">
                                <strong>{dashboard && dashboard.total_request_retur} Permintaan Return</strong>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="card bg-primary">
                        <div className="card-body text-white">
                            <h3 className="text-white">
                                <strong>{dashboard && dashboard.total_retur} Produk Return</strong>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-header">
                <ChartsWidget1 title='Penjualan 30 Hari Terakhir' className={'mb-5 mb-xxl-8'} />
            </div>
            <div className="box-header">
                <ChartsWidget2 title='Penjualan Pertahun' className={'mb-5 mb-xxl-8'} />
            </div>
            {/* <div className="box-header">
                <ChartsWidget1 title='Laba Penjualan' className={'mb-5 mb-xxl-8'} />
            </div> */}
        </div>

    )
}

export default DashboardPage
