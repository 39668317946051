import React from 'react'
import ShiftRecapPage from '../../modules/cassier/ShiftRecapPage'

const ShiftRecap = () => {
    return (
        <div>
            <ShiftRecapPage></ShiftRecapPage>
        </div>
    )
}

export default ShiftRecap