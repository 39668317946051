import React from 'react'
import DashboardPage from '../../modules/admintbk/DashboardPage'
import ManagementAccountPage from '../../modules/admintbk/ManagementAccountPage'

const ManagementAAccount = () => {
    return (
        <ManagementAccountPage></ManagementAccountPage>
    )
}

export default ManagementAAccount