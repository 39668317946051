import React from 'react'
import { Button } from 'react-bootstrap'
import { KTCard } from '../../../_metronic/helpers'

const HistoryReturnPage = () => {
    return (
        <>
            <KTCard>
                <div className='container'>
                    <div className=' card-header border-0 mt-6'>
                        <div className='row gap-4 fw-bold'>
                            <div className='col-3'>
                                <label className='mb-2'>Nama Product</label>
                                <input placeholder='Kopi Susu' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />

                                {/* <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="ON_PACKING">
                                        <div>
                                            <p>Susu kedelai</p>
                                            <p>20000</p>
                                        </div>
                                    </option>
                                    <option value="ON_PACKING">
                                        <div>
                                            <p>Susu Coklat</p>
                                            <p>20000</p>
                                        </div>
                                    </option>
                                    <option value="ON_PACKING">
                                        <div>
                                            <p>Susu Putih</p>
                                            <p>20000</p>
                                        </div>
                                    </option>
                                </select> */}
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Jumlah</label>
                                <input placeholder='1' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Dari</label>
                                <input type="date" placeholder='dd/mm/yyyy' className='form-control' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Sampai</label>
                                <input type="date" placeholder='dd/mm/yyyy' className='form-control' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            {/* <div className='col-3'>
                                <label className='mb-2'>Keteranan</label>
                                <input placeholder='Barang rusak' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div> */}

                            <div className='col-3'>
                                <label className='mb-2'>Arrange by</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Input Date</option>
                                    <option value="ON_DELIVERY">Kategori</option>
                                    <option value="RECEIVED">Kode</option>
                                    <option value="CANCELED">Nama Product</option>
                                </select>
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Order</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Ascending</option>
                                    <option value="ON_DELIVERY">Descending</option>
                                </select>
                            </div>
                            <div className='col-3 mt-8'>
                                <Button>Search</Button>
                            </div>
                        </div>
                    </div>
                    {/* begin::Table container */}
                    <div className='card-body'>
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th align='center' className='min-w-30px'>
                                            NO
                                        </th>
                                        <th className='min-w-150px'>Kode</th>
                                        <th className='min-w-140px'>Tanggal</th>
                                        {/* <th className='min-w-120px'>Jumlah</th> */}
                                        <th className='min-w-120px'>Nama Product</th>
                                        <th className='min-w-120px'>Jumlah</th>
                                        <th className='min-w-120px'>Harga </th>
                                        <th className='min-w-120px'>Sub Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {Array.from(Array(10).keys()).map((val: number) => (
                                        <tr key={val}>
                                            <td align='center'>
                                                {val + 1}
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    8992753722747
                                                </a>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    17 September 2023 13:34:34
                                                </a>
                                            </td>

                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    DAPUR KITA KALDU RASA JAMUR 1OGR
                                                </a>
                                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Web, UI/UX Design
                                </span> */}
                                            </td>
                                            <td align='center' className='text-dark fw-bold text-hover-primary fs-6'>2
                                            </td>
                                            <td>
                                                <span>Rp.100.000,00</span>
                                            </td>
                                            <td>
                                                <span>Rp.200.000,00</span>
                                            </td>
                                            <td className="d-flex">
                                                <button className="btn btn-info btn-sm me-2 flex-fill" type="button" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-whatever="@mdo">
                                                    Detail
                                                </button>

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* pagination */}
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div aria-label="..." id='kt_table_users_paginate'>
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* end::Table container */}
                        <div className="modal fade" id="editModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Permintaan</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="mb-3">
                                                <label className="col-form-label">Nama Produk:</label>
                                                <input type="text" placeholder={"FRISIAN RIYADI"} className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Jumlah:</label>
                                                <input type="number" className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Alasan:</label>
                                                <input type="text" className="form-control" id="recipient-name" />
                                            </div>

                                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Simpan Perubahan</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </KTCard>
        </>
    )
}

export default HistoryReturnPage