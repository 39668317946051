import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { KTCard } from '../../../_metronic/helpers';
import { useAuth } from '../auth';





const ShiftRecapPage = () => {
    const { currentUser, logout } = useAuth()
    const [currentTime, setCurrentTime] = useState(getCurrentTime());

    useEffect(() => {
        // Update the current time every second
        const intervalId = setInterval(() => {
            setCurrentTime(getCurrentTime());
        }, 1000);

        return () => {
            // Clear the interval when the component unmounts
            clearInterval(intervalId);
        };
    }, []);

    function getCurrentTime() {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    return (
        <>
            <KTCard>
                <div className='container px-12 pt-6 '>
                    <h2 className='text-center mb-8'>Rekap Shift Anda</h2>
                    <div className="d-flex justify-content-center mb-6">
                        <Form style={{
                            width: "720px"
                        }}>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Nama Kasir</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" placeholder="Input here..." />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formGroupEmail">
                                <Form.Label column sm="4" className="fw-bold">Tanggal</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"08/10/2023"} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Jam Mulai</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" value={"08:00"} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" className="fw-bold">Jam Berakhir</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" value={currentTime} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Transaksi Cash</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp. 100.000" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Transaksi EDC</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp. 100.000" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Diskon</Form.Label>
                                <Col sm="8">
                                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                                        <thead>
                                            <tr className="fw-bolder fs-6 text-gray-800">
                                                <th>Diskon Cash</th>
                                                <th>Diskon EDC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Rp. 100.000</td>
                                                <td>Rp. 100.000</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* <Form.Control disabled type="text" placeholder="Rp. 100.000" /> */}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Retur</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="10" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Total Transaksi</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp. 100.000" />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Cash Drawer</Form.Label>
                                <Col sm="8">
                                    <Form.Control type="number" placeholder="Input here..." />
                                </Col>
                            </Form.Group> */}
                            {/* <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Selisih Cash Drawer</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp.100.000,00" />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Total Nota</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="10" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formGroupPassword">
                                <Form.Label column sm="4" clasaName="fw-bold">Saldo Awal</Form.Label>
                                <Col sm="8">
                                    <Form.Control disabled type="text" placeholder="Rp.200.000,00" />
                                </Col>
                            </Form.Group>
                            <div className="d-flex justify-content-center mt-8">
                                <Button variant="primary" size="lg" onClick={logout}>
                                    Save & Log Out
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </KTCard>
        </>

    )
}

export default ShiftRecapPage