import React from 'react'
import LapKeuanganPage from '../../modules/admintbk/LapKeuanganPage'
import ProductPage from '../../modules/cassier/ProductPage'

const LaporanProduk = () =>{
  return (
    <ProductPage></ProductPage>
    )
}

export default LaporanProduk