import React, { useState } from 'react'
import { BiSortAlt2 } from 'react-icons/bi'
import { ChartsWidget1, ChartsWidget3, ChartsWidget4 } from '../../../_metronic/partials/widgets';
import { KTCard } from '../../../_metronic/helpers';
import { Button } from 'react-bootstrap';

type dummyDataLaporan = {
    id: number;
    produk: string;
    jumlah: number;
    hpp: string;
    omzet: number;
    profit: string;

};

const dummyLaporan = [
    {
        id: 1,
        produk: "FRISIAN FLAG SKM POUCH CHOCOLATE 280GR",
        jumlah: 100,
        hpp: "17.800",
        omzet: "23.000",
        profit: "5.300",
    },
    {
        id: 2,
        produk: "CAKE CASES MIKA M",
        jumlah: 152,
        hpp: "48.100",
        omzet: "90.650",
        profit: "42.550",
    },
    {
        id: 3,
        produk: "CAKE CASES MIKA KECIL",
        jumlah: 187,
        hpp: "11.500",
        omzet: "19.000",
        profit: "7.500",
    },
    {
        id: 4,
        produk: "TEPUNG SEGITIGA BIRU 1KG",
        jumlah: 30,
        hpp: "298.200",
        omzet: "330.000",
        profit: "31.800",
    },
]

function LapKeuanganPage() {
    const [sortDirection, setSortDirection] = useState('asc');
    const [data, setData] = useState(dummyLaporan);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(dummyLaporan);
    const [tableData, setTableData] = useState<dummyDataLaporan[]>([]);
    const [addedProducts, setAddedProducts] = useState<dummyDataLaporan[]>([]);


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchValue(value);

        const filtered = dummyLaporan.filter((item) =>
            // item.kode.toLowerCase().includes(value.toLowerCase()) ||
            item.produk.toLowerCase().includes(value.toLowerCase())

        );

        setFilteredData(filtered);
    };

    const handleAddToTable = (itemToAdd: dummyDataLaporan) => {
        if (!addedProducts.some((item) => item.produk === itemToAdd.produk)) {
            setTableData([...tableData, itemToAdd]);
            setAddedProducts([...addedProducts, itemToAdd]);
        }
    };


    const toggleSortDirection = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        // Jika urutan saat ini adalah 'asc', ubah menjadi 'desc', dan sebaliknya.
        // Anda juga dapat menambahkan logika sorting di sini.
    };

    // Fungsi untuk melakukan sorting berdasarkan kolom tertentu
    const sortData = (columnName: 'produk' | 'jumlah' | 'hpp' | 'omzet' | 'profit') => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            const columnA = a[columnName];
            const columnB = b[columnName];

            if (sortDirection === 'asc') {
                if (typeof columnA === 'number' && typeof columnB === 'number') {
                    return columnA - columnB;
                } else if (typeof columnA === 'string' && typeof columnB === 'string') {
                    return columnA.localeCompare(columnB);
                }
            } else {
                if (typeof columnA === 'number' && typeof columnB === 'number') {
                    return columnB - columnA;
                } else if (typeof columnA === 'string' && typeof columnB === 'string') {
                    return columnB.localeCompare(columnA);
                }
            }

            return 0; // Fallback, jika tipe tidak cocok
        });
        setData(sortedData);
    };
    const hargaBertingkat = [
        { terjual: "80", harga: "Rp. 22.000", jumlah: "5 pcs" },
        { terjual: "20", harga: "Rp. 5.000", jumlah: "1 pcs" }

    ];

    return (
        <>

            <div className='container'>
                <div className='card-header row justify-content-between border-0 mt-4 mb-3 shadow-lg p-3 bg-secondary rounded'>


                    <div className=' card-header border-0'>
                        <div className='row gap-4 fw-bold'>
                            <div className='col-3'>
                                <label className='mb-2'></label>
                                <div style={{ position: 'relative' }}>
                                    <i className="fas fa-search" style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888', pointerEvents: 'none' }}></i>
                                    <input placeholder='Search' className='form-control ps-9' type='text' />
                                </div>

                            </div>
                            <div className='col-3'>
                                <label className='mb-2'></label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Harian</option>
                                    <option value="ON_DELIVERY">Bulanan</option>
                                </select>
                            </div>
                            <div className='col-3 mt-6'>
                                <Button><i className="bi bi-printer" style={{ marginRight: '5px' }}></i>Print</Button>
                            </div>
                        </div>
                    </div>
                    {/* <div className='d-flex align-items-center position-relative my-1 col-3'>
                        <i className="fas fa-search position-absolute ms-3"></i>
                        <input className="form-control w-200px ps-9"

                            placeholder='Search'
                            data-kt-user-table-filter="search"
                            value={searchValue}
                            onChange={handleSearchChange}
                        >
                        </input>

                    </div>
                    <div className='col-3'>
                        <label className='mb-2'>Arrange by</label>
                        <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                            <option value="PICKED_UP">Input Date</option>
                            <option value="ON_DELIVERY">Kategori</option>
                            <option value="RECEIVED">Kode</option>
                            <option value="CANCELED">Nama Product</option>
                        </select>
                    </div>

                    <div className='col-3'>
                        <div className="dropdown">
                            <button

                                className='btn btn-danger mt-6'
                                data-bs-toggle="dropdown"
                                aria-expanded="false"

                            >
                                Print
                                <i className="bi bi-printer" style={{ marginLeft: '5px' }}></i>
                            </button>

                        </div>
                    </div>
                    {searchValue && (
                        <div className="card-title">
                            <div className="search-results position-absolute w-30 bg-white border rounded " style={{ marginTop: "-15px", width: "15%" }} >
                                <ul className="list-group" >
                                    {filteredData
                                        .filter((item) => !addedProducts.some((addedItem) => addedItem.produk === item.produk))
                                        .map((item) => (
                                            <a
                                                key={item.produk}
                                                style={{ cursor: "pointer" }}
                                                className="list-group-item d-flex justify-content-between align-items-center"

                                            >
                                                {item.produk}
                                                <button
                                                    className="btn btn-light-primary btn-sm"
                                                    style={{ marginLeft: "5px" }}
                                                >
                                                    <i className="bi bi-plus-lg align-items-center"></i>
                                                </button>
                                            </a>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    )} */}
                </div>


                {/* Komponen tabel */}
                <div className='card-body mt-10'>
                    <div className="d-flex flex-column">
                        <li className="d-flex align-items-center py-2">
                            <span className="bullet me-5"></span>Jumlah Terjual : 469
                        </li>
                        <li className="d-flex align-items-center py-2">
                            <span className="bullet me-5"></span>Total HPP : RP.375.600
                        </li>
                        <li className="d-flex align-items-center py-2">
                            <span className="bullet me-5"></span>Total Omzet : RP.462.650
                        </li>
                        <li className="d-flex align-items-center py-2">
                            <span className="bullet me-5"></span>Total Profit : RP.87.150
                        </li>

                    </div>
                    <div className='table-responsive'>
                        <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                            <thead>
                                <tr className='fw-bold text-muted' style={{ cursor: "pointer" }}>
                                    <th align='center' className='min-w-30px'>
                                        NO
                                    </th>
                                    <th className='min-w-150px' onClick={() => { sortData('produk'); toggleSortDirection(); }}>
                                        PRODUK
                                        {sortDirection === 'asc' ? <BiSortAlt2 /> : <BiSortAlt2 />}
                                    </th>
                                    <th className='min-w-140px' onClick={() => { sortData('jumlah'); toggleSortDirection(); }}>
                                        JUMLAH {sortDirection === 'asc' ? <BiSortAlt2 /> : <BiSortAlt2 />}
                                    </th>
                                    <th className='min-w-120px' onClick={() => { sortData('hpp'); toggleSortDirection(); }}>
                                        HPP {sortDirection === 'asc' ? <BiSortAlt2 /> : <BiSortAlt2 />}
                                    </th>
                                    <th className='min-w-120px' onClick={() => { sortData('omzet'); toggleSortDirection(); }}>
                                        OMZET {sortDirection === 'asc' ? <BiSortAlt2 /> : <BiSortAlt2 />}
                                    </th>
                                    <th className='min-w-120px' onClick={() => { sortData('profit'); toggleSortDirection(); }}>
                                        PROFIT {sortDirection === 'asc' ? <BiSortAlt2 /> : <BiSortAlt2 />}
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => (
                                    <tr key={item.id}>
                                        <td align='center'>{item.id}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-text"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                data-bs-whatever="@mdo"
                                            >
                                                {item.produk}
                                            </button>
                                        </td>
                                        <td>
                                            <a
                                                href='#'
                                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                            >
                                                {item.jumlah}
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                href='#'
                                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                            >
                                                {item.hpp}
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                href='#'
                                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                            >
                                                {item.omzet}
                                            </a>
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item.profit}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* pagination */}
                    <div className='row'>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <div aria-label="..." id='kt_table_users_paginate'>
                                <ul className="pagination">
                                    <li className="page-item disabled">
                                        <a className="page-link">Previous</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item active" aria-current="page">
                                        <a className="page-link" href="#">2</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">Next</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* end::Table container */}
                    {/* modal */}
                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Detail Product</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="col-form-label">Nama Product:</label>
                                            <input type="text" value={"FRISIAN FLAG SKM POUCH CHOCOLATE 280GR"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                        {/* <div className="mb-3">
                                            <label className="col-form-label">Kategori:</label>
                                            <input type="text" value={"Susu"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Kode:</label>
                                            <input type="text" value={"8992753722747"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Jumlah:</label>
                                            <input type="text" value={"20"} disabled className="form-control" id="recipient-name" />
                                        </div> */}
                                        <div className="mb-3">
                                            <label className="col-form-label">Terjual:</label>
                                            {hargaBertingkat.map((item, index) => (
                                                <div key={index} className="d-flex mb-3">
                                                    <input type="text" value={item.terjual} disabled className="form-control me-2" id="recipient-name" />
                                                    <input type="text" value={item.harga}
                                                        disabled className="form-control me-2" id="recipient-name" />
                                                    <input type="text" value={item.jumlah} disabled className="form-control" id="recipient-name" />

                                                </div>
                                            ))}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box-header">
                        <ChartsWidget3 title='Laba Penjualan 30 Hari Terakhir' className={'mb-5 mb-xxl-8'} />
                    </div>
                    <div className="box-header">
                        <ChartsWidget4 title='Laba Penjualan Pertahun' className={'mb-5 mb-xxl-8'} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default LapKeuanganPage