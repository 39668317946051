import React, { useState, useEffect } from 'react';
// import SearchProductModal from './components/SearchProductModal';
import { convertIDR, formatCurrency } from '../../functions/global';
import Pembayaran from '../apps/components/Pembayaran';
import TahanTransaksi from '../apps/components/TahanTransaksi';

interface Product {
  id: number;
  kode: string;
  name: string;
  tanggal: string,
  jumlah: string,
  diskon: string,
  price: string;
}

interface SearchProductModalProps {
  show: boolean;
  onClose: () => void;
  onSelectProduct: (product: Product) => void;
}


const DashboardPage = () => {
  // const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('0');
  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [showPayment, setShowPayment] = useState(false);
  const [showTahanTransaksi, setShowTahanTransaksi] = useState(false);
  const [productQty, setProductQty] = useState<any>({});
  const [amountPay, setAmountPay] = useState<number>(0)
  const [selectedProduct, setSelectedProduct] = useState<Product | null | any>([
  ]);
  const [filteredData, setFilteredData] = useState([
    { id: 6, name: 'Roti keju Lapis', kode: "123123", sisa: "10", tanggal: "17 September 2023", jumlah: "1 pcs", diskon: "20%", price: "19000" },
    { id: 7, name: 'Roti keju Lapis', kode: "123123", sisa: "25", tanggal: "17 September 2023", jumlah: "12 pcs", diskon: "20%", price: "50000" },
    { id: 8, name: 'KOPI KAPAL API', kode: "123123", sisa: "5 ", tanggal: "17 September 2023", jumlah: "1 pcs", diskon: "10%", price: "19000" },
    { id: 9, name: 'MARIPAK', kode: "123123", sisa: "13 ", tanggal: "17 September 2023", jumlah: "1 pcs", diskon: "50%", price: "19000" },
    { id: 10, name: 'MIE INSTAN', kode: "123123", sisa: "10 ", tanggal: "17 September 2023", jumlah: "1 pcs", diskon: "10%", price: "19000" },
    { id: 11, name: 'AROMA BOLD', kode: "123123", sisa: "20 ", tanggal: "17 September 2023", jumlah: "1 pcs", diskon: "10%", price: "19000" },
    { id: 11, name: 'KEJU DUAPULUH TUJUH LAPIS PANJANG KAN', kode: "123123", sisa: "20 ", tanggal: "17 September 2023", jumlah: "1 pcs", diskon: "10%", price: "19000" },
  ]);
  const [filtereNewdData, setFiltereNewdData] = useState<any>([]); // Initialize with an empty array


  const handlePaymentClick = () => {
    setShowPayment(true);
  };

  const handleClosePayment = () => {
    setShowPayment(false);
  };
  const handlertahan = () => {
    setShowPayment(false);
    setSelectedProduct([])
  };
  const handleTahanTransaksiClick = () => {
    setShowTahanTransaksi(true);
  };

  const handleCloseTahanTransaksi = () => {
    setShowTahanTransaksi(false);
    setSelectedProduct([])

  };



  const handleInputChange = (e: any) => {
    const number = formatCurrency(e.target.value)
    console.log('nilaai', number);

    setInputValue(number);
  };

  // Function to close the modal
  const closeModal = () => {
    if (parseInt(inputValue) > 0) {
      // If input is not empty, close the modal
      setShowModal(false);
    } else {
      // If input is empty, you can show an error message or take other actions
      alert('Input cannot be empty');
    }
  };


  const handleSearchProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    const filtered = filteredData.filter((item) =>
      item.kode.toLowerCase().includes(value.toLowerCase()) ||
      item.name.toLowerCase().includes(value.toLowerCase())

    );

    setFiltereNewdData(filtered);
  };


  const handleSelectProduct = (product: any) => {
    const existingProduct = selectedProduct.find((item: Product) => item.id === product.id);

    if (existingProduct) {
      // If it exists, update the quantity
      const updatedProducts = selectedProduct.map((item: Product) => {
        if (item.id === product.id) {
          const updatedQuantity = parseInt(item.jumlah) + 1; // Increase quantity
          return { ...item, jumlah: updatedQuantity.toString() };
        }
        return item;
      });

      setSelectedProduct(updatedProducts);
    } else {
      setSelectedProduct([...selectedProduct, product]);
    }
    setSearchValue("")
  }


  const products: Product[] = [
    // Replace with your product data
    { id: 3, name: 'Susu Kental Manis', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "10%", price: "19000" },
    { id: 4, name: 'Product 2', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: "29000" },
    { id: 5, name: 'Product 3', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "50%", price: "39000" },
    // Add more products as needed
  ];

  const handleChangeQty = (productId: string, qty: string) => {

    const updatedProductQty = { ...productQty, [productId]: qty };

    if (parseInt(qty) < 1) {
      alert("minimal 1")
      setProductQty({ ...productQty, [productId]: 1 })
      const updatedSelectedProduct = selectedProduct.map((product: { id: string; }) => {
        if (product.id === productId) {
          return { ...product, jumlah: 1 };
        }
        return product;
      });

      setSelectedProduct(updatedSelectedProduct);
    } else {
      setProductQty(updatedProductQty);


      // Hitung ulang subtotal
      // const newSubtotal = subTotal(qty, selectedProduct.find((product: { id: string; }) => product.id === productId).diskon, selectedProduct.find((product: { id: string; }) => product.id === productId).price);

      // Simpan subtotal dalam state produk yang sesuai
      const updatedSelectedProduct = selectedProduct.map((product: { id: string; }) => {
        if (product.id === productId) {
          return { ...product, jumlah: qty };
        }
        return product;
      });

      setSelectedProduct(updatedSelectedProduct);

    }
  };

  const handleRemoveItem = (id: number) => { // Update the parameter type to string
    const updatedTableData = selectedProduct.filter((product: Product) => product.id !== id);
    setSelectedProduct(updatedTableData);
  };



  const subTotal = (qty: string, diskon: string, price: string) => {

    let totalAmount = parseInt(qty) * parseInt(price);

    let discountAmount = totalAmount * (parseInt(diskon)) / 100

    return totalAmount - discountAmount
  }

  const totalQty = () => {
    let total = 0;
    selectedProduct.map((product: { jumlah: string; }) => {
      total += parseInt(product.jumlah);
    });
    return total;
  }

  const TotalAmount = () => {
    let total = 0
    selectedProduct.forEach((product: { jumlah: string, diskon: string, price: string; }) => {
      total += subTotal(product.jumlah, product.diskon, product.price)
    });
    return total
  }

  const [paymentMethod, setPaymentMethod] = useState<string>('');

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentMethod(event.target.value);
  };


  return (
    <div className='container'>
      <div>
        {showModal && (

          <div className="modal fade show" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">INPUT SALDO AWAL CASH DRAWER
                  </h1>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label className="col-form-label">Input Saldo Awal Cash Drawer
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            closeModal();
                          }
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='card-header row justify-content-between border-0 mt-4 mb-3 shadow-lg p-3 rounded'>
        <h2>Penjualan Produk</h2>
        <div className='col-12 col-md-2 mb-2 mt-4'>
          {/* <label className='mb-2'>Nama Product</label> */}
          <div>
            {/* <button className="btn btn-primary" onClick={handleOpenModal}>
              Tambah Product
            </button> */}
            <div className='d-flex align-items-center position-relative my-1 col-3'>
              <i className="fas fa-search position-absolute ms-3"></i>
              <input className="form-control w-200px ps-9"

                placeholder='Search'
                data-kt-user-table-filter="search"
                value={searchValue}
                onChange={e => handleSearchProduct(e)}
                autoFocus>
              </input>
            </div>


            {/* <SearchProductModal
              show={showModal}
              onClose={handleCloseModal}
              onSelectProduct={handleSelectProduct}
              products={products}
            /> */}
          </div>


        </div>
        {/* <div className='col-12 col-md-2 mb-2 '>
          <div className="dropdown">
            <button

              className='btn btn-primary mt-6'
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Transaksi
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ textAlign: "center" }}>
              <li>
                <button
                  className='btn btn-white btn-sm'
                  onClick={handlePaymentClick}
                >
                  Pembayaran
                </button>
              </li>
              <li>
                <button
                  className='btn btn-white btn-sm'
                  onClick={handleTahanTransaksiClick}
                >
                  Tahan Transaksi
                </button>
              </li>
            </ul>
          </div>
        </div> */}
        {searchValue && (
          <div className="card-title">
            <div className="search-results position-absolute bg-white border rounded " style={{ marginTop: "-15px", width: "auto" }} >

              <ul className="list-group" >
                {filteredData
                  // .filter((item) => !addedProducts.some((addedItem) => addedItem.kode === item.kode))
                  .map((item) => (
                    <a
                      key={item.kode}
                      style={{ cursor: "pointer" }}
                      className="list-group-item d-flex justify-content-between align-items-center"
                      onClick={() => handleSelectProduct(item)}
                    >
                      <div style={{ flex: 1 }}>
                        {item.name}
                      </div>
                      <div className="ms-auto" style={{ flex: 0.5 }}>
                        Rp.{item.price}
                      </div>
                      <div style={{ flex: 0.5 }}>
                        {item.jumlah}
                      </div>
                      <div  >
                        {item.sisa}
                      </div>
                    </a>

                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>

      <Pembayaran showPayment={showPayment} onClosePayment={handleClosePayment} onTahanPayment={handlertahan} />
      <TahanTransaksi showTahanTransaksi={showTahanTransaksi} onCloseTahanTransaksi={handleCloseTahanTransaksi} />
      {/* <div className='card-body row justify-content-between border-0 mt-2 mb-3 shadow-lg p-3 bg-secondary rounded'> */}
      <div className='row container-fluid border-0 mt-4 mb-3 shadow-lg bg-white rounded'>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th align='center' className='min-w-30px'>
                  NO
                </th>
                <th className='min-w-150px'>KODE</th>
                <th className='min-w-150px'>NAMA PRODUK</th>
                <th className='min-w-125px'>JUMLAH</th>
                <th className='min-w-125px'>HARGA</th>
                <th className='min-w-125px'>DISKON</th>
                <th className='min-w-140px'>SUBTOTAL</th>
                <th className='min-w-80px'>ACTION</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {selectedProduct.map((val: any) => (
                <tr key={val.id} >
                  <td align='center'>
                    {val.id}
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                      {val.kode}
                    </a>
                  </td>
                  <td>
                    <p className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {val.name}
                    </p>
                  </td>
                  <td align='center' className='text-dark fw-bold text-hover-primary fs-6'>

                    <input
                      className='form-control'
                      type='number'
                      value={productQty[val.id] ? productQty[val.id] : val.jumlah}
                      onChange={(e) => handleChangeQty(val.id, e.target.value)}

                    />
                  </td>
                  <td>
                    <span>{convertIDR(val.price)}</span>
                  </td>
                  <td>
                    <span>{val.diskon}</span>
                  </td>
                  <td>
                    <span>{convertIDR(subTotal(val.jumlah, val.diskon, val.price))}</span>
                  </td>
                  <td>
                    <button className="btn btn-white btn-sm" onClick={() => handleRemoveItem(val.id)}>
                      <i className="bi bi-x-lg"></i>
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}></td>
                <td colSpan={2}>
                  <h3>Jumlah Barang</h3>
                </td>
                <td colSpan={2}>
                  <h3>{totalQty()}</h3>
                </td>
              </tr>
              {/* <tr>
              <td colSpan={6}></td>
              <td>
                <h5 className='text-light text-bold-success'>Diskon</h5>
              </td>
              <td>
                <h5 className='text text-success'>0</h5>
              </td>
            </tr> */}
              <tr>
                <td colSpan={4}></td>
                <td colSpan={2}>
                  <h3>Bayar</h3>
                </td>
                <td colSpan={2}>

                  <input
                    className='form-control'
                    type='number'
                    value={amountPay}
                    onChange={(e) => {
                      const formattedAmount = parseFloat(e.target.value)
                      setAmountPay(formattedAmount);
                    }} />
                  <h6 className='mt-2'>{convertIDR(amountPay)}</h6>
                </td>
              </tr>
              <tr>
                <td colSpan={4}></td>
                <td colSpan={2}>
                  <h1>Grand Total</h1>
                </td>
                <td colSpan={2}>
                  <h2>Rp. 50.000,00</h2>
                </td>
              </tr>
              <tr>
                <td colSpan={4}></td>
                <td colSpan={2}>
                  <h2>Kembali</h2>
                </td>
                <td colSpan={2}>
                  <h4>{convertIDR(amountPay - TotalAmount())}</h4>
                </td>
              </tr>
              <tr>
                <td colSpan={4}></td>
                <td colSpan={2}>
                  <h2>Metode Pembayaran</h2>
                </td>
                <td colSpan={2}>
                  <select
                    className="form-control"
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                  >
                    <option value="">Pilih Metode Pembayaran</option>
                    <option value="tunai">Tunai</option>
                    <option value="qris">QRIS</option>
                    <option value="transfer">Transfer</option>
                  </select>

                </td>
              </tr>
              <td colSpan={8} className="text-end">
                <button className='btn btn-primary me-2' onClick={handlePaymentClick}>
                  Pembayaran
                </button>
                <button className='btn btn-danger' onClick={handleTahanTransaksiClick}>
                  Tahan Transaksi
                </button>
              </td>
            </tbody>

            {/* end::Table body */}
          </table>
          {/* <div className="d-flex justify-content-end mt-4">
            <table className="table table-striped table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-7" style={{ width: '50%', textAlign: 'center' }}>
              <tbody>
                <tr>

                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }}> <h3>JUMLAH BARANG</h3> </td>
                  <td >
                    <td className='border'>
                      <h1><strong>{totalQty()}</strong></h1>

                    </td>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }}> <h2>GRAND TOTAL</h2> </td>
                  <td className='border'>
                    <h1><strong>{convertIDR(TotalAmount())}</strong></h1>

                  </td>
                </tr>
                <tr>
                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }}> <h2>BAYAR</h2></td>
                  <td >
                    <input
                      className='form-control'
                      type='number'
                      value={amountPay}
                      onChange={(e) => setAmountPay(parseInt(e.target.value))}
                    />
                    <h6 className='text-light text-bold-success mt-2'>{convertIDR(amountPay)}</h6>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }} > <h3>KEMBALIAN</h3> </td>
                  <td>
                    <h1><strong>{convertIDR(amountPay - TotalAmount())}</strong></h1>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {/* end::Table */}
        </div>
        {/* <div className="d-grid">
          <button className="btn btn-primary" type="button">finish</button>
        </div> */}
      </div>
      {/* </div> */}
    </div>

  )
}

export default DashboardPage
