import React from 'react'
import DashboardPage from '../../modules/cassier/DashboardPage'
import HistoryReturn from '../cassier/HistoryReturn'
import LaporanStokPage from '../../modules/admintbk/LaporanStokPage'

const LaporanStok = () => {
    return (
        <div>
            <LaporanStokPage></LaporanStokPage>
        </div>
    )
}

export default LaporanStok
