import React from 'react'
import MemberPage from '../../modules/cassier/MemberPage'

const Member = () => {
    return (
        <div>
            <MemberPage></MemberPage>
        </div>
    )
}

export default Member