import React from 'react'
import ProductPage from '../../modules/cassier/ProductPage'

const Product = () => {
    return (
        <div>
            <ProductPage></ProductPage>
        </div>
    )
}

export default Product