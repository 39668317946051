/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { setAuth } from '../core/AuthHelpers'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      
      try {
        const auth = await login(values.username, values.password);
        const { token, user } = auth.data;
        setCurrentUser(user);
        setAuth(token)
       sessionStorage.setItem('token', token);
       sessionStorage.setItem('user', JSON.stringify(user));              
      } catch (error) {
        console.log('errorlogin', error);
        saveAuth(undefined);
        setCurrentUser(undefined);
        setStatus('The login details are incorrect');
        alert("Username or password is incorrect");
      } finally {
        setLoading(false);
      }
    },
  })

  return (
    <form
      className='form w-100'
      noValidate
      id='kt_login_signin_form'
    >
    <div className='text-center mb-11 flex-column'>
      <h1 className='text-dark fw-bolder mb-3'>Masuk Kedalam Aplikasi Kasir</h1>
      <h1 className='text-dark fw-bolder mb'>D'HARJOSOEWARNO </h1>
    </div>
    <div className='fv-row mb-8'>
      <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
      <input
        type='username'
        {...formik.getFieldProps('username')}
        placeholder='Username'
        name='username'
        className=
        'form-control bg-transparent'

        autoComplete='off'
      />
{formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
    </div>
    <div className='fv-row mb-3'>
      <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
      <input
        type='password'
        {...formik.getFieldProps('password')}
        placeholder='Password'
        autoComplete='off'
        name='password'
        className=
        'form-control bg-transparent'
      />

{formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
    </div>
    <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
      <div />
    </div>
    <div className='d-grid mb-10'>
    <button
  type='button'  // Ganti dari 'submit' ke 'button'
  id='kt_sign_in_submit'
  className='btn btn-primary'
  onClick={() => formik.handleSubmit()}  // Tangani submit secara manual
  disabled={loading || formik.isSubmitting || !formik.isValid}
>
  <span className='indicator-label'>Continue</span>
  {loading && (
    <span className='indicator-progress' style={{ display: 'block' }}>
      Please wait...
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )}
</button>

    </div>
    <div className='row g-3 mb-9'>
      <div className='col-md-6'>
      </div>
      <div className='col-md-6'>
      </div>
    </div>
    </form>
  )
}
