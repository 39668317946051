import React, { useState } from 'react';
interface Product {
    id: number;
    kode: string;
    name: string;
    tanggal: string,
    jumlah: string,
    diskon: string,
    price: number;
}
const SupplierPage = () => {
    const [showForm, setShowForm] = useState(false);
    const [users, setUsers] = useState<Array<{ nama: string; notelp: string; }>>([]);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null | any>([
        { id: 1, name: 'Product 1', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 19000 },
        { id: 2, name: 'Product 2', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 29000 },
    ]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSelectProduct = (product: Product) => {
        let newProduct = product
        setSelectedProduct([...selectedProduct, newProduct]);
        setShowModal(false);
    };
    const data = [
        { harga: "Rp. 5.000", jumlah: "1 pcs" },
        { harga: "Rp. 500.000", jumlah: "1 box" }

    ];
    const [formData, setFormData] = useState({
        nama: '',
        notelp: '',
    });
    const handleDeleteUser = (index: number) => {
        const updatedUsers = users.filter((_, i) => i !== index);
        setUsers(updatedUsers);
    }
    const handleToggleForm = () => {
        setShowForm(!showForm);
    }

    const handleSaveUser = () => {
        setUsers([...users, formData]);
        setFormData({
            nama: '',
            notelp: '',
        });
        setShowForm(false);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }




    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <section className="content-header content-header-custom">
                        <h1>Data Supplier</h1>
                    </section>
                </div>
            </div>
            <div className='row my-5'>
                <div className='col-12 col-md-2 mb-2'>
                    {showForm ? (
                        <div className="card p-4 shadow bg-white">
                            <label htmlFor="nama">Nama</label>
                            <input
                                type="text"
                                id="nama"
                                name="nama"
                                className="form-control mb-2"
                                onChange={handleChange}
                            />

                            <label htmlFor="fullname"> Notelp</label>
                            <input
                                type="text"
                                id="notelp"
                                name="notelp"
                                className="form-control mb-2"
                                onChange={handleChange2}
                            />

                            <button
                                className='btn btn-primary mt-2'
                                onClick={handleSaveUser}
                            >
                                Simpan
                            </button>
                        </div>
                    ) : (
                        <button
                            className='btn btn-primary mt-2'
                            onClick={handleToggleForm}
                        >
                            Tambah User
                        </button>
                    )}
                </div>
            </div>

            <div className='row container-fluid border-0 mt-4 mb-3 shadow-lg bg-white rounded'>
                <div className='table-responsive'>
                    <table className='table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>No</th>
                                <th className='min-w-150px'>Nama Supplier</th>
                                <th className='min-w-125px'>Telepon</th>
                                <th className='min-w-125px'>Daftar Produk</th>
                                <th className='min-w-80px'>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={index}>
                                    <td align='center'>{index + 1}</td>
                                    <td>{user.nama}</td>
                                    <td>{user.notelp}</td>
                                    <td>
                                        <button type="button" className="btn btn-text" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                            Lihat Produk
                                        </a></button>
                                    </td>
                                    <td>
                                        <button className="btn btn-info btn-sm">Edit</button>
                                        <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDeleteUser(index)}>Hapus</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">ListProduk</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <label className="col-form-label">Nama Product:</label>
                                <div className="mb-3">
                                    <input type="text" value={"FRISIAN FLAG SKM POUCH CHOCOLATE 280GR"} disabled className="form-control" id="recipient-name" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" value={"FRISIAN FLAG SKM"} disabled className="form-control" id="recipient-name" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" value={"Dancow"} disabled className="form-control" id="recipient-name" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" value={"FRISIAN FLAG S"} disabled className="form-control" id="recipient-name" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplierPage;
