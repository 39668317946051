export function convertIDR(price: number): string {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumSignificantDigits: 9,
    }).format(price)
  }
  export function formatCurrency(amount: string) {
    const amountNumber = parseInt(amount.replace(/,/g, ''), 10); // Ini akan menjadi angka 1000000
    const formattedAmount = isNaN(amountNumber) ? '0' : amountNumber.toLocaleString('en-US');
    return formattedAmount

  }
  
  

  export function convertDate(date: string) {
    const month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
  
    const dataDate = new Date(date.replace(" ", "T"));
    return `${dataDate.getDate()} ${month[dataDate.getMonth()]
      } ${dataDate.getFullYear()}`;
  }
  
  export function convertTime(date: string) {
    const dataDate = new Date(date.replace(" ", "T"));
    const hours =
      dataDate.getHours() <= 9 ? "0" + dataDate.getHours() : dataDate.getHours();
    const minutes =
      dataDate.getMinutes() <= 9
        ? "0" + dataDate.getMinutes()
        : dataDate.getMinutes();
  
    return `${hours}:${minutes}`;
  }