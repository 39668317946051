import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { KTCard } from '../../../../_metronic/helpers';




interface Product {
    id: number;
    kode: string;
    name: string;
    tanggal: string,
    jumlah: string,
    diskon: string,
    price: number;
}

const StokProductPage = () => {
    const [hargaJualFields, setHargaJualFields] = useState([{ harga: '', jumlah: '', jenis: '' }]);

    const handleTambahHarga = () => {
        setHargaJualFields([...hargaJualFields, { harga: '', jumlah: '', jenis: '' }]);
    };
    const handleJenisChange = (value: string, index: number) => {
        const updatedFields = [...hargaJualFields];
        updatedFields[index].jenis = value;
        setHargaJualFields(updatedFields);
    };
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null | any>([
        { id: 1, name: 'Product 1', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 19000 },
        { id: 2, name: 'Product 2', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 29000 },
    ]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleAddStock = () => {
        setShowModal(false);
    };
    const handleSelectProduct = (product: Product) => {
        let newProduct = product
        setSelectedProduct([...selectedProduct, newProduct]);
        setShowModal(false);
    };
    const data = [
        { harga: "Rp. 5.000", jumlah: "1 pcs" },
        { harga: "Rp. 500.000", jumlah: "1 box" }

    ];

    return (
        <>
            <KTCard>
                <div className='card pt-6'>
                    <div className=' card-header border-0'>
                        <div className='row gap-4 fw-bold'>
                            <div className=''>
                                <h2>SIMAS MARGARIN 1KG (kategori : margarin)</h2>

                                <span>Periode Tanggal:</span> <br />
                                <span>01 Januari 2022 sampai 10 Oktober 2023</span>

                                <h1 className='mt-10'>History Input Stok</h1>
                            </div>
                        </div>
                    </div>
                    {/* tabel */}
                    <div className='card-body'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted text-center'>
                                        <th align='center' className='min-w-30px'>
                                            Tgl input
                                        </th>
                                        <th className='min-w-30px'>Expired</th>
                                        <th className='min-w-30px'>Jml Masuk</th>
                                        <th className='min-w-30px'>HPP</th>
                                        <th className='min-w-30px'>Suplier</th>
                                        <th className='min-w-120px'>Keterangan</th>
                                        <th className='min-w-30px'>PJ</th>
                                        <th className='min-w-30px'>Action</th>
                                        <th className='min-w-30px'>EXP Notif</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {Array.from(Array(5).keys()).map(() => (
                                        <tr className='text-center'>


                                            <td>
                                                27 Januari 2022
                                            </td>
                                            <td>
                                                18 September 2022
                                            </td>
                                            <td>
                                                <div className='text-center text-dark fw-bold d-block mb-1 fs-6'>
                                                    10
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    RP. 20.000
                                                </div>
                                            </td>
                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    INTI PRIMA
                                                </div>
                                            </td>
                                            <td>
                                                Penyesuaian Stok Opnam
                                                {/* <button className="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#editModalLabel" data-bs-whatever="@mdo">Edit</button>
                                            <span>  </span>

                                            <button className="btn btn-danger btn-sm">  History</button> */}
                                            </td>
                                            <td>
                                                Manager <br /> Pusat
                                            </td>
                                            <td>
                                                <button className="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#editModalLabel" data-bs-whatever="@mdo">Edit</button>
                                                <br />
                                                <button type="button" className="btn btn-danger btn-sm mt-2" ><a href='#' className='text-white' >
                                                    Refund
                                                </a></button>
                                            </td>
                                            <td>
                                                <div>
                                                    <input type="checkbox" id="checkbox1" className="form-check-input rounded-circle" />
                                                    <label htmlFor="checkbox1" className="form-check-label">On</label>
                                                </div>
                                                <div className='mt-4'>
                                                    <input type="checkbox" id="checkbox2" className="form-check-input rounded-circle" />
                                                    <label htmlFor="checkbox2" className="form-check-label">Off</label>
                                                </div>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                <tbody>
                                    <tr className='text-center'>
                                        <td></td>
                                        <td><h2>TOTAL</h2></td>
                                        <td className='text-dark fw-bold'>50</td>
                                    </tr>

                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* pagination */}

                        {/* end::Table container */}
                        {/* modal */}
                        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Detail Product</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="mb-3">
                                                <label className="col-form-label">Nama Product:</label>
                                                <input type="text" value={"FRISIAN FLAG SKM POUCH CHOCOLATE 280GR"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Kategori:</label>
                                                <input type="text" value={"Susu"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Kode:</label>
                                                <input type="text" value={"8992753722747"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Jumlah:</label>
                                                <input type="text" value={"20"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Harga Jual:</label>
                                                {data.map((item, index) => (
                                                    <div key={index} className="d-flex mb-3">
                                                        <input type="text" value={item.harga}
                                                            disabled className="form-control me-2" id="recipient-name" />
                                                        <input type="text" value={item.jumlah} disabled className="form-control" id="recipient-name" />
                                                    </div>
                                                ))}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="editModalLabel" aria-labelledby="editModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="editModalLabel">Edit Stok</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="col-form-label">HPP:</label>
                                            <input type="text" placeholder="HPP" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Suplier:</label>
                                            <input type="text" placeholder="Suplier" className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Keterangan:</label>
                                            <input type="text" placeholder="Keterangan" className="form-control" />
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" >Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="deleteModal" aria-labelledby="deleteModal" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Hapus Produk</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Anda ingin menghapus Stok Ini ?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Hapus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END :: Tabel History Input Stok */}

                {/* Tabel History Refund */}
                <div className='card pt-6'>
                    <div className=' card-header border-0'>
                        <div className='row gap-4 fw-bold'>
                            <div className=''>
                                <h1 className='mt-10'>History Refund</h1>
                            </div>
                        </div>
                    </div>
                    {/* tabel */}
                    <div className='card-body'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted text-center'>
                                        <th align='center' className='min-w-30px'>
                                            No
                                        </th>
                                        <th className='min-w-30px'>Tgl Refund</th>
                                        <th className='min-w-30px'>Jumlah</th>
                                        <th className='min-w-30px'>Nominal</th>
                                        <th className='min-w-30px'>Suplier</th>
                                        <th className='min-w-120px'>No Faktur</th>
                                        <th className='min-w-30px'>Penanggung Jawab</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {Array.from(Array(3).keys()).map((val: number) => (
                                        <tr key={val} className='text-center'>
                                            <td align='center'>
                                                {val + 1}
                                            </td>
                                            <td>
                                                18 September 2022
                                            </td>
                                            <td>
                                                4
                                            </td>
                                            <td>
                                                6
                                            </td>
                                            <td>
                                                INTI PRIMA
                                            </td>
                                            <td>
                                                00229876554
                                            </td>
                                            <td>
                                                Manajer Pusat
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                <tbody>

                                    <tr className='text-center'>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <div className='text-center text-dark fw-bold d-block mb-1 fs-6'>
                                                <h2>TOTAL</h2>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                18
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* end:: Table History Refund*/}

                    {/* Tabel Produk Keluar/Hilang/Expired */}
                    <div className='card pt-6'>
                        <div className=' card-header border-0'>
                            <div className='row gap-4 fw-bold'>
                                <div className=''>
                                    <h1 className='mt-10'>Produk Keluar / Hilang / Expired</h1>
                                </div>
                            </div>
                        </div>
                        {/* tabel */}
                        <div className='card-body'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted text-center'>
                                            <th align='center' className='min-w-30px'>
                                                No
                                            </th>
                                            <th className='min-w-30px'>Tanggal</th>
                                            <th className='min-w-30px'>Jumlah</th>
                                            <th className='min-w-30px'>Keterangan</th>
                                            <th className='min-w-30px'>Penanggung Jawab</th>
                                        </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                        {Array.from(Array(5).keys()).map((val: number) => (
                                            <tr key={val} className='text-center'>
                                                <td align='center'>
                                                    {val + 1}
                                                </td>
                                                <td>
                                                    18 September 2022
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        2

                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        Penyesuaian Stok Opname
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        Manager Pusat
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tbody>
                                        <tr className='text-center'>
                                            <td></td>

                                            <td className='text-dark fw-bold d-block mb-1 fs-6'>
                                                <h1>TOTAL</h1>
                                            </td>
                                            <td className='text-dark fw-bold '> 10</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* End :: Tabel Produk Keluar/Hilang/Expired */}


                    {/* History Terjual */}
                    <div className='card pt-6'>
                        <div className=' card-header border-0'>
                            <div className='row gap-4 fw-bold'>
                                <div className=''>
                                    <h1 className='mt-10'>History Terjual</h1>
                                </div>
                            </div>
                        </div>
                        {/* tabel */}
                        <div className='card-body'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted text-center'>
                                            <th align='center' className='min-w-30px'>
                                                No
                                            </th>
                                            <th className='min-w-30px'>No Nota</th>
                                            <th className='min-w-30px'>Tanggal</th>
                                            <th className='min-w-30px'>Jumlah</th>
                                            <th className='min-w-30px'>Kasir</th>
                                            <th className='min-w-30px'>Jenis Penjulan</th>
                                            <th className='min-w-30px'>Nominal Terjual</th>
                                            <th className='min-w-30px'>Pembayaran</th>
                                        </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                        {Array.from(Array(5).keys()).map((val: number) => (
                                            <tr key={val} className='text-center'>
                                                <td align='center'>
                                                    {val + 1}
                                                </td>
                                                <td>
                                                    <a href="">102</a>
                                                </td>
                                                <td>
                                                    18 September 2022
                                                </td>
                                                <td>
                                                    <div className=''>
                                                        2

                                                    </div>
                                                </td>
                                                <td>
                                                    Aldi
                                                </td>
                                                <td>
                                                    <div className=''>
                                                        Cust Umum
                                                    </div>
                                                </td>
                                                <td>
                                                    RP. 37.980
                                                </td>
                                                <td>
                                                    <div className=''>
                                                        Tunai
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tbody>
                                        <tr className='text-center'>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <h2>TOTAL</h2>
                                            </td>
                                            <td>10</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    {/* End :: Tabel History Terjual */}

                </div>
            </KTCard>
        </>
    )
}

export default StokProductPage