import React from 'react'
import TransactionPage from '../../modules/cassier/TransactionPage'

const Transaction = () => {
    return (
        <div>
            <TransactionPage></TransactionPage>
        </div>
    )
}

export default Transaction