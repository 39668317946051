import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { KTCard } from '../../../_metronic/helpers'
import SearchDropdown from '../cassier/components/SearchDropdown'

interface Product {
    id: number;
    kode: string;
    name: string;
    tanggal: string,
    jumlah: string,
    diskon: string,
    price: number;
}

const KategoriProdukPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null | any>([
        { id: 1, name: 'Product 1', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 19000 },
        { id: 2, name: 'Product 2', kode: "123123", tanggal: "17 September 2023", jumlah: "1", diskon: "0%", price: 29000 },
    ]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSelectProduct = (product: Product) => {
        let newProduct = product
        setSelectedProduct([...selectedProduct, newProduct]);
        setShowModal(false);
    };
    const data = [
        { harga: "Rp. 5.000", jumlah: "1 pcs" },
        { harga: "Rp. 500.000", jumlah: "1 box" }

    ];


    return (
        <>
            <KTCard>
                <div className='card pt-6'>
                    <div className='card-header m-grid-responsive-xs gap-1'>
                        <div className='col-3'>
                            <label className='mb-2'>Nama Kategori</label>
                            <input placeholder='Enter your product name' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                        </div>
                        <div className='col-3'>
                            <label className='mb-2'>Kode Kategori</label>
                            <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                <option value="PICKED_UP">Ascending</option>
                                <option value="ON_DELIVERY">Descending</option>
                            </select>
                        </div>
                        <div className='col-3'>
                            <label className='mb-2'>Order</label>
                            <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                <option value="PICKED_UP">Ascending</option>
                                <option value="ON_DELIVERY">Descending</option>
                            </select>
                        </div>
                        <div className='col-2 mt-8'>
                            <Button className='m-grid-responsive-xs'>Search</Button>
                        </div>
                        <div className='col-2 mt-3 my-5'>
                            <Button className='m-grid-responsive-xs'>Tambah Produk </Button>
                        </div>
                    </div>

                    {/* tabel */}
                    <div className='card-body'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th align='center' className='min-w-30px'>
                                            NO
                                        </th>
                                        <th className='min-w-150px'>Nama Kategori</th>
                                        <th className='min-w-140px'>Kode</th>
                                        <th className='min-w-120px'>Option</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {Array.from(Array(10).keys()).map((val: number) => (
                                        <tr key={val}>
                                            <td align='center'>
                                                {val + 1}
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-text"><a href='/AllProduk' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    FRISIAN FLAG SKM POUCH CHOCOLATE 280GR
                                                </a></button>

                                            </td>

                                            <td>
                                                <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    8992753722747
                                                </div>
                                            </td>
                                            <td>
                                                <button className="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#editModalLabel" data-bs-whatever="@mdo">Edit</button>
                                                <span>  </span>
                                                <button className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#deleteModal" data-bs-whatever="@mdo">hapus</button>
                                            </td>


                                        </tr>
                                    ))}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* pagination */}
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div aria-label="..." id='kt_table_users_paginate'>
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* end::Table container */}
                        {/* modal */}
                        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Detail Kategori</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="mb-3">
                                                <label className="col-form-label">Nama Kategori:</label>
                                                <input type="text" value={"FRISIAN FLAG SKM POUCH CHOCOLATE 280GR"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="col-form-label">Kode:</label>
                                                <input type="text" value={"8992753722747"} disabled className="form-control" id="recipient-name" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="deleteModal" aria-labelledby="deleteModal" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Hapus Produk</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Anda ingin menghapus Stok Ini ?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Hapus</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </KTCard>
        </>
    )
}

export default KategoriProdukPage