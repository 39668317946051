import React from 'react'
import { TablesWidget14 } from '../../../_metronic/partials/widgets/tables/TablesWidget14'
import { Button } from 'react-bootstrap'
import { KTCard } from '../../../_metronic/helpers'


const MemberPage = () => {
    return (
        <>
            <KTCard>
                <div className='container'>
                    <div className=' card-header border-0 mt-6'>
                        <div className='row gap-4 fw-bold'>
                            <div className='col-3'>
                                <label className='mb-2'>Nama</label>
                                <input placeholder='Budi' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Kode</label>
                                <input placeholder='09898' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Alamat</label>
                                <input placeholder='Jl.Bagaswara' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Telepon</label>
                                <input placeholder='+ 62' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Email</label>
                                <input placeholder='budi@gmail.com' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Order</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Ascending</option>
                                    <option value="ON_DELIVERY">Descending</option>
                                </select>
                            </div>
                            <div className='col-3 mt-8'>
                                <Button>Search</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* begin::Table container */}
                <div className='card-body'>
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th align='center' className='min-w-30px'>
                                        NO
                                    </th>
                                    <th className='min-w-150px'>Nama</th>
                                    <th className='min-w-120px'>Kode</th>
                                    {/* <th className='min-w-140px'>Alamat</th> */}
                                    <th className='min-w-120px'>Telpon</th>
                                    {/* <th className='min-w-120px'>Email</th> */}
                                    <th className='min-w-120px'>Diskon</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {Array.from(Array(10).keys()).map((val: number) => (
                                    <tr key={val}>
                                        <td align='center'>
                                            {val + 1}
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-text" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                FRISIAN RIYADI
                                            </a></button>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                8992753722747
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: PH</span>
                                        </td>
                                        {/* <td>
                                            <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                Nglumut
                                                Kec. Srumbung
                                                Kabupaten Magelang
                                                Jawa Tengah
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: Paid</span>
                                        </td> */}
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                089887151456
                                            </a>
                                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Web, UI/UX Design
                                </span> */}
                                        </td>
                                        {/* <td className='text-dark fw-bold text-hover-primary fs-6'>tigaRasa@gmail.com
                                        </td> */}
                                        <td>
                                            <span className='badge badge-light-success'>50%</span>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <div aria-label="..." id='kt_table_users_paginate'>
                                <ul className="pagination">
                                    <li className="page-item disabled">
                                        <a className="page-link">Previous</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item active" aria-current="page">
                                        <a className="page-link" href="#">2</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#">Next</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* end::Table container */}
                    {/* modal */}
                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Detail Product</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="col-form-label">Nama:</label>
                                            <input type="text" value={"FRISIAN RIYADI"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Alamat:</label>
                                            <input type="text" value={"Nglumut,Srumbung,Magelang,Jawa Tengah"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Telepon:</label>
                                            <input type="text" value={"089887151456"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="col-form-label">Email:</label>
                                            <input type="text" value={"tigaRasa@gmail.com"} disabled className="form-control" id="recipient-name" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </KTCard>
        </>
    )
}

export default MemberPage