import React, { useState, useEffect } from 'react';
import { ChartsWidget1 } from '../../../_metronic/partials/widgets';
// import SearchProductModal from './components/SearchProductModal';
import { convertIDR, formatCurrency } from '../../functions/global';
import Pembayaran from '../apps/components/Pembayaran';
import TahanTransaksi from '../apps/components/TahanTransaksi';

interface Product {
    id: number;
    kode: string;
    name: string;
    tanggal: string,
    jumlah: string,
    diskon: string,
    price: string;
}

interface SearchProductModalProps {
    show: boolean;
    onClose: () => void;
    onSelectProduct: (product: Product) => void;
}


const ManagementAccountPage = () => {
    const [show, setShow] = useState(false);
  

     const handleshow = () =>{
        setShow(!show)
     }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <section className="content-header content-header-custom">
                        <h1>Management User</h1>
                    </section>
                </div>
            </div>
            <div className='row my-5'>
               { show === true &&( <div className='col-30 col-md-2 mb-2'>
               <div className="card p-4 shadow bg-white">
                        <label htmlFor="username">Username</label>
                        <input type="text" id="username" className="form-control mb-2" />

                        <label htmlFor="fullname">Full Name</label>
                        <input type="text" id="fullname" className="form-control mb-2" />

                        <label htmlFor="level">Level</label>
                        <select id="level" className="form-select mb-2">
                            <option value="admin">Admin</option>
                            <option value="kasir">Kasir</option>
                            <option value="gudang">Gudang</option>
                        </select>

                        <label htmlFor="password">Set Password</label>
                        <input type="password" id="password" className="form-control mb-2" />

                        <button
                            className='btn btn-primary mt-2'
                            onClick={handleshow}
                        >
                            Simpan
                        </button>
                    </div>
                </div>)}
               { show === false &&( <div className='col-12 col-md-2 mb-2'>
                    <div>
                        <button
                            className='btn btn-primary mt-2'
                            onClick={handleshow}
                        >
                            Tambah User
                        </button>
                    </div>
                </div>)}
            </div>


            <div className='row container-fluid border-0 mt-4 mb-3 shadow-lg bg-white rounded'>
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min-w-150px'>No</th>
                                <th className='min-w-150px'>Username</th>
                                <th className='min-w-125px'>Nama</th>
                                <th className='min-w-125px'>Level</th>
                                <th className='min-w-125px'>Status</th>
                                <th className='min-w-80px'>ACTION</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {Array.from(Array(10).keys()).map((val: number) => (
                                <tr key={val}>
                                    <td align='center'>
                                        {val + 1}
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-text" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"><a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                            FRISIANs
                                        </a></button>
                                    </td>
                                    <td>
                                        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            FRISIAN RIYADI
                                        </a>
                                    </td>
                                    <td>
                                        <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            admin
                                        </a>
                                    </td>
                                    <td>
                                        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                            Aktif
                                        </a>
                                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Web, UI/UX Design
                                </span> */}
                                    </td>
                                    <td>
                                        <button className="btn btn-info btn-sm">
                                            <i className="bi">edit</i>
                                        </button>
                                        <span>   </span>
                                        <button className="btn btn-danger btn-sm mr-5"> {/* Menambahkan class "mr-2" untuk memberikan margin kanan */}
                                            <i className="bi">Non Aktifkan</i>
                                        </button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* <div className="d-flex justify-content-end mt-4">
            <table className="table table-striped table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-7" style={{ width: '50%', textAlign: 'center' }}>
              <tbody>
                <tr>

                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }}> <h3>JUMLAH BARANG</h3> </td>
                  <td >
                    <td className='border'>
                      <h1><strong>{totalQty()}</strong></h1>

                    </td>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }}> <h2>GRAND TOTAL</h2> </td>
                  <td className='border'>
                    <h1><strong>{convertIDR(TotalAmount())}</strong></h1>

                  </td>
                </tr>
                <tr>
                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }}> <h2>BAYAR</h2></td>
                  <td >
                    <input
                      className='form-control'
                      type='number'
                      value={amountPay}
                      onChange={(e) => setAmountPay(parseInt(e.target.value))}
                    />
                    <h6 className='text-light text-bold-success mt-2'>{convertIDR(amountPay)}</h6>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bolder text-gray-800" style={{ width: '50%' }} > <h3>KEMBALIAN</h3> </td>
                  <td>
                    <h1><strong>{convertIDR(amountPay - TotalAmount())}</strong></h1>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
                    {/* end::Table */}
                </div>
                {/* <div className="d-grid">
          <button className="btn btn-primary" type="button">finish</button>
        </div> */}
            </div>
        </div>

    )
}

export default ManagementAccountPage
