import jsPDF from 'jspdf';
import React, { useRef, useState } from 'react';
import logo from '../../../../_metronic/assets/img/logo3.png'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';


interface TransaksiProps {
    showPayment: boolean;
    onClosePayment: () => void;
    onTahanPayment: () => void;
}

const Pembayaran: React.FC<TransaksiProps> = ({ showPayment, onClosePayment, onTahanPayment }) => {
    // const [paymentMethod, setPaymentMethod] = useState<string>('');

    // const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     setPaymentMethod(event.target.value);
    // };

    const handlePrintReceipt = () => {

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [48, 210],
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const logoWidth = 10; // Lebar logo dalam mm, sesuai dengan lebar kertas
        const logoHeight = 10; // Tinggi logo dalam mm
        const logoX = 20; // Letak X logo dalam mm
        const logoY = 10; // Letak Y logo dalam mm, di atas teks "D Hardjosoewarno"

        // Tambahkan logo ke PDF
        pdf.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

        pdf.setFontSize(8);


        // pdf.addImage(logo, "PNG", 15, 27, 180, 180);
        pdf.text('D Hardjosoewarno', pageWidth / 2, 25, { align: 'center' });
        pdf.text('Jl Parangtritis no.133, Brontok', pageWidth / 2, 30, { align: 'center' });
        pdf.text('usuman, Yogyakarta', pageWidth / 2, 35, { align: 'center' });
        pdf.text('081390408934', pageWidth / 2, 40, { align: 'center' });

        pdf.text(`No Nota : 12345`, 2, 45);
        pdf.text(`Kasir   : John Doe`, 2, 50);
        pdf.text(`Tanggal : 2023-10-03`, 2, 55);

        pdf.setLineWidth(0.5);
        pdf.line(1, 60, pageWidth - 1, 60);

        const lineHeight = 5;
        const nextLineY = 55 + lineHeight;

        pdf.text(`SUSU INDOMILK `, 2, nextLineY + 10);
        pdf.text(`2 x 7.500 = 15.000`, 2, nextLineY + 15);
        pdf.text(`ROTI AOKA`, 2, nextLineY + 20);
        pdf.text(`4 x 3.000 = 12.000`, 2, nextLineY + 25);
        pdf.text(`KEJU SLICE PROCIS `, 2, nextLineY + 30);
        pdf.text(`ISI 5PSC 1 x 14.000 = 14.000`, 2, nextLineY + 35);

        const dashLength = 3;
        const gapLength = 3;
        let currentY = nextLineY + 40;
        let drawDash = true;

        while (currentY < nextLineY + 45) {
            if (drawDash) {
                pdf.line(1, currentY, pageWidth - 1, currentY);
            }
            drawDash = !drawDash;
            currentY += dashLength + gapLength;
        }


        pdf.text(`Total: $41,000`, 2, nextLineY + 47);
        pdf.text(`Metode Pembayaran:  `, 2, nextLineY + 52); /* ${paymentMethod} */
        pdf.text(`Jumlah Barang: 7`, 2, nextLineY + 57);
        pdf.text(`Tunai: 0`, 2, nextLineY + 62);
        pdf.text(`Diskon: 0`, 2, nextLineY + 67);
        pdf.text(`Kembalian: 0`, 2, nextLineY + 72);

        pdf.text('Maaf barang yang sudah dibeli', pageWidth / 2, nextLineY + 80, { align: 'center' });
        pdf.text('tidak dapat ditukar atau kembali', pageWidth / 2, nextLineY + 85, { align: 'center' });
        pdf.text('kan', pageWidth / 2, nextLineY + 90, { align: 'center' });

        const blob = pdf.output('blob');
        const url = URL.createObjectURL(blob);

        const newWindow = window.open(url, '_blank');

        if (newWindow) {
            newWindow.onload = () => {
                newWindow.print();
            };
        } else {
            alert('Tidak dapat membuka jendela baru. Pastikan popup tidak diblokir.');
        };
    }

    return (
        <div>
            {showPayment && (
                <div className='modal-backdrop fade show'></div>
            )}
            {showPayment && (
                <div className="modal fade show d-flex align-items-center justify-content-center' id='kt_modal_add_user' style={{ display: 'block' }}">
                    <div className="modal-dialog modal-lg">
                        <div className="container-fluid modal-content">
                            <div className="modal-header">
                                <button type='button' className='btn-close' onClick={onClosePayment}></button>
                            </div>
                            <form id='kt_modal_add_user_form' className='form' noValidate>

                                <div
                                    className='d-flex flex-column scroll-y me-n7 pe-7'
                                    id='kt_modal_add_user_scroll'
                                    data-kt-scroll='true'
                                    data-kt-scroll-activate='{default: false, lg: true}'
                                    data-kt-scroll-max-height='auto'
                                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                                    data-kt-scroll-offset='300px'
                                >
                                    <div className="container-fluid ">
                                        <div className="row">
                                            <div className="">
                                                <h4>Informasi Transaksi</h4>
                                                <p>Kasir: </p>
                                                <p>No Nota: </p>
                                                <p>Tanggal: </p>
                                                <p>Jam: </p>
                                                <p>Status:</p>
                                            </div>
                                            <div className="">
                                                <h4>Detail Transaksi</h4>
                                                {/* <div className="form-group">
                                                    <label>Metode Pembayaran:</label>
                                                    <select
                                                        className="form-control"
                                                    value={paymentMethod}
                                                    onChange={handlePaymentMethodChange}
                                                    >
                                                        <option value="">Pilih Metode Pembayaran</option>
                                                        <option value="tunai">Tunai</option>
                                                        <option value="qris">QRIS</option>
                                                        <option value="transfer">Transfer</option>

                                                    </select>
                                                </div>
                                                <br /> */}
                                                <p>Grand Total: </p>
                                                <p>Bayar: </p>
                                                <p>Diskon: </p>
                                                <p>Kembalian: </p>
                                            </div>
                                        </div>
                                        {/* <div className="row mt-4">
                                            <div className="col-md-12">
                                                <h4>Detail Produk</h4>
                                                <p>Grand Total: </p>
                                                <p>Bayar: </p>
                                                <p>Kembalian: </p>
                                                <p>Diskon: </p>
                                                <p>Nominal: </p>
                                            </div>
                                        </div> */}

                                    </div>



                                </div>

                                <div className='text-center pt-15 m-5'>

                                    <button
                                        type='button'
                                        className='btn btn-primary'

                                        onClick={handlePrintReceipt}

                                    >
                                        <span className='indicator-label'>Cetak</span>
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        style={{ marginLeft: "7px" }}
                                        onClick={onTahanPayment}

                                    >
                                        <span className='indicator-label'>Tahan</span>
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>


            )}
        </div>
    );
};

export default Pembayaran;
