import React, { useState } from 'react';

interface TahanTransaksiProps {
    showTahanTransaksi: boolean;
    onCloseTahanTransaksi: () => void;
}
const TahanTransaksi: React.FC<TahanTransaksiProps> = ({ showTahanTransaksi, onCloseTahanTransaksi }) => {

    return (
        <>
            {showTahanTransaksi && (
                <div className='modal-backdrop fade show'></div>
            )}
            {showTahanTransaksi && (
                <div className='modal fade show d-flex align-items-center justify-content-center' id='kt_modal_add_user' style={{ display: 'block' }}>
                    <div className='modal-dialog modal-lg'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Transaksi Terkini</h5>
                                <button type='button' className='btn-close' onClick={onCloseTahanTransaksi}></button>
                            </div>
                            <div className='modal-body'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ paddingRight: "10px" }}>1</td>
                                            <td style={{ paddingRight: "50px" }}>2023/0038 (Walk-In Customer)</td>
                                            <td style={{ paddingRight: "30px" }}>13,200</td>
                                            <td style={{ paddingRight: "10px" }}><a href=""><i className="bi bi-pen-fill"></i></a></td>
                                            <td style={{ paddingRight: "10px" }}><a href=""><i className="bi bi-trash-fill"></i></a></td>
                                            <td style={{ paddingRight: "10px" }}><a href=""><i className="bi bi-printer-fill"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td >2</td>
                                            <td>2023/0037 (Walk-In Customer)</td>
                                            <td>9,800</td>
                                            <td><a href=""><i className="bi bi-pen-fill"></i></a></td>
                                            <td><a href=""><i className="bi bi-trash-fill"></i></a></td>
                                            <td><a href=""><i className="bi bi-printer-fill"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td >3</td>
                                            <td>2023/0036 (Walk-In Customer)</td>
                                            <td>1,300</td>
                                            <td><a href=""><i className="bi bi-pen-fill"></i></a></td>
                                            <td><a href=""><i className="bi bi-trash-fill"></i></a></td>
                                            <td><a href=""><i className="bi bi-printer-fill"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <form id='kt_modal_add_user_form' className='form' noValidate>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-secondary' onClick={onCloseTahanTransaksi}>
                                    Tutup
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TahanTransaksi 