import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { KTCard } from '../../../_metronic/helpers'
import { showTransaksi } from '../../functions/global/api';

const TransactionPage = () => {
    const [transaksi, setTransaksi] = useState<any[]>([]);
    const [Kategori, setKategori] = useState<any[]>([]);

    const showData = () => {

        showTransaksi()
            .then(data => {
                setTransaksi(data);
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });
    };

    useEffect(() => {
        showData();
    }, []);

    return (
        <>
            <KTCard>
                <div className='container'>
                    <div className=' card-header border-0 mt-6'>
                        <div className='row gap-4 fw-bold'>
                            <div className='col-4'>
                                <label className='mb-2'>Nomor Notas</label>
                                <input placeholder='0123' className='form-control' type='text' name='statusDeliveryCode' onChange={(e) => console.log(e)} />
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Tanggal</label>
                                <input className='form-control' type='date' name='dateStart' onChange={(e) => console.log(e)} />
                            </div>

                            <div className='col-3'>
                                <label className='mb-2'>Arrange by</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Input Date</option>
                                    <option value="ON_DELIVERY">Kategori</option>
                                    <option value="RECEIVED">Kode</option>
                                    <option value="CANCELED">Nama Product</option>
                                </select>
                            </div>
                            <div className='col-3'>
                                <label className='mb-2'>Kategori</label>
                                <select className="form-select" name='modeProcess' onChange={(e) => console.log(e)}>
                                    <option value="PICKED_UP">Agar-Agar</option>
                                    <option value="ON_DELIVERY">Air Minum</option>
                                    <option value="RECEIVED">Alat</option>
                                    <option value="CANCELED">Bahan</option>
                                </select>
                            </div>
                            <div className='col-3 mt-8'>
                                <Button>Search</Button>
                            </div>
                        </div>
                    </div>

                    {/* begin::Table container */}
                    <div className='card-body'>
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className=' table table-striped table-row-bordered table-row-white-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th align='center' className='min-w-30px'>
                                            NO
                                        </th>
                                        <th className='min-w-150px'>No Nota</th>
                                        <th className='min-w-120px'>Tanggal</th>
                                        <th className='min-w-140px'>Nominal</th>
                                        <th className='min-w-120px'>Sub Total</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {transaksi.map((transaksi, index) => (
                                        <tr key={index}>
                                            <td align="center">{index + 1}</td>
                                            <td>

                                                <button type="button" className="btn btn-text">
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {transaksi.nota_number}
                                                    </a>
                                                </button>

                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {transaksi.date}
                                                </span>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>Status: {transaksi.status}</span>
                                            </td>
                                            <td>
                                                <a href="" className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    {transaksi.total}
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>Payment: {transaksi.payment_method}</span>
                                            </td>
                                            <td>
                                                <span style={{ fontSize: '14px' }} className='badge badge-light-success '>RP.{transaksi.grand_total}</span>
                                            </td>
                                        </tr>
                                    ))}
                                    {/* {Array.from(Array(10).keys()).map((val: number) => (
                                        <tr key={val}>
                                            <td align='center'>
                                                {val + 1}
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                    hehe
                                                </a>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    17 September 2023 13:34:34
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: PH</span>
                                            </td>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                    Rp.200.000,00
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: Paid</span>
                                            </td>
                                            <td>
                                                <span className='badge badge-light-success'>Rp.200.000,00</span>
                                            </td>

                                        </tr>
                                    ))} */}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* pagination */}
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div aria-label="..." id='kt_table_users_paginate'>
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end::Table container */}

                </div>
            </KTCard>
        </>
    )
}

export default TransactionPage