import React from 'react'
import KategoriProdukPage from '../../modules/admintbk/KategoriProdukPage'

const KategoriProduk = () => {
    return (
        <div>
            <KategoriProdukPage></KategoriProdukPage>
        </div>
    )
}

export default KategoriProduk