import axios from 'axios'
import { SupllierModel, ProductModel } from './modelSupplier'
import { log } from 'console'

const API_URL = process.env.REACT_APP_API_URL

/* SUPPLIER */
export const SHOWSUPPLIER_URL = `${API_URL}/supplier?search`
export const ADDSUPPLIER_URL = `${API_URL}/supplier`
export const UPDATESUPPLIER_URL = `${API_URL}/supplier/{id_supplier}`

/* ALL PRODUK */
export const SHOWPRODUK_URL = `${API_URL}/product?search=&category_id=`
export const DELPRODUK_URL = `${API_URL}/product/{id_product}`
export const ADDPRODUK_URL = `${API_URL}/product`
export const UPDATEPRODUK_URL = `${API_URL}/product/{product_id}`

/* AKUN */
export const SHOWAKUN_URL = `${API_URL}/user?search`
export const UPDATEAKUN_URL = `${API_URL}/user/:user_id`
export const ADDAKUN_URL = `${API_URL}/user`

/* PERMINTAAN RETURN */
export const SHOWREQ_URL = `${API_URL}/product-return?page=1`

/* PERMINTAAN RETURN */
export const SHOWTRANSAKSI_URL = `${API_URL}/transaction?page=1`

/* CATEGORY */
export const CATEGORY_URL = `${API_URL}/master/category`

/* DASHBOARD */
export const DASHBOARD_URL = `${API_URL}/dashboard`

/* Data Kasir */
export const SHOWKASIR_URL = `${API_URL}/cashier?page=1`
export const UPDATEKASIR_URL = `${API_URL}/cashier/:user_id`

/* LAPORAN KEUANGAN */
export const KEUANGAN_URL = `${API_URL}/report/financial?type=monthly`
export const PENJUALAN_URL = `${API_URL}/report/financial?type=monthly`
export const HARIAN_URL = `${API_URL}/report/financial?type=monthly`


export async function fetchCategories() {
    try {
        const response = await axios.get(CATEGORY_URL);
        return response.data.data;
    } catch (error) {
        console.error('Gagal mengambil data kategori', error);
        return [];
    }
}

/* SUPPLIER */
export function showSupplier() {
    return axios.get(SHOWSUPPLIER_URL)
        .then(response => response.data.data.data);
}
export function addSupplier(name: string, phone: string) {
    return axios.post<SupllierModel>(ADDSUPPLIER_URL, {
        name,
        phone,
    }).then(response => response.data)

}

export function deleteSupplier(id_supplier: number) {
    return axios.delete(`${API_URL}/supplier/${id_supplier}}`, {

    });
}

export function updateSupplier(id: number, name: string, phone: string) {
    const url = UPDATESUPPLIER_URL.replace('{id_supplier}', id.toString());
    console.log('dat', UPDATESUPPLIER_URL);

    return axios.post<SupllierModel>(url, {
        name,
        phone,
    }).then(response => response.data);
}

/* ALL PRODUK */
export function showProducts(search = '', category_id = '') {
    return axios.get(SHOWPRODUK_URL, {
        params: {
            search,
            category_id,
        }
    })
        .then(response => response.data.data.data);
}

export function addNewProduct(newProductData: {
    category_id: string;
    product_name: string;
    product_code: string;
    stock: number;
    price: number,
    supplier_id: string,
    hpp_price: number,
    faktur_number: number,
    expired_date: string,
    expired_notif_date: string,
    unit_id: number[],
    unit_price: number[],
    minimum: number[],
}) {
    return axios.post(ADDPRODUK_URL, newProductData)
        .then(response => response.data);
}

export function deleteProduct(id_product: number) {
    return axios.delete(`${API_URL}/product/${id_product}`, {

    });
}

export function updateProduct(id: number, category_id: number, product_name: string, product_code: string, stock: number, price: number) {
    const url = UPDATEPRODUK_URL.replace('{id_product}', id.toString());
    return axios.post(url, {
        category_id,
        product_name,
        product_code,
        stock,
        price,
    })
        .then(response => response.data);
}

/* AKUN */
export function showAkun() {
    return axios.get(SHOWAKUN_URL)
        .then(response => response.data.data.data);
}

export function addNewAkun(newAkunData: { name: string; username: string; phone: string; email: string; role: string; password: string }) {
    return axios.post(ADDAKUN_URL, newAkunData)
        .then(response => response.data);
}

export function updateAccount(id: number, name: string, email: string, password: string, username: string, phone: string, role: string) {
    const url = UPDATEAKUN_URL.replace(':user_id', id.toString());
    return axios.post(url, {
        name,
        email,
        password,
        username,
        phone,
        role,
    })
        .then(response => response.data);

}


// export function deleteAkun(:user_id: number) {
//     return axios.delete(`${API_URL}/supplier/:user_id}`, {

//     });
// }
export function deleteAccount(userId: number) {
    const url = `${API_URL}/user/${userId}`;

    return axios.delete(url)
        .then(response => response.data);
}

/* Data Kasir */
export function showKasir() {
    return axios.get(SHOWKASIR_URL)
        .then(response => response.data.data.data);
}
export function updateKasir(id: number, name: string, username: string, email: string, phone: string, password: string) {
    const url = UPDATEKASIR_URL.replace(':user_id', id.toString());
    console.log('dat', UPDATESUPPLIER_URL);
    return axios.post(url, {
        name,
        username,
        email,
        phone,
        password,
    }).then(response => response.data);
}
export function deleteKasir(user_id: number) {
    return axios.delete(`${API_URL}/cashier/${user_id}}`, {

    });
}

/* Permintaan Return */
export function showreq() {
    return axios.get(SHOWREQ_URL)
        .then(response => response.data.data.data);
}

/* Data Transaksi */
export function showTransaksi() {
    return axios.get(SHOWTRANSAKSI_URL)
        .then(response => response.data.data.data);
}

/* Dashboard */
export function showDashboard() {
    return axios.get(DASHBOARD_URL)
        .then(response => response.data.data);
}

/* Laporan */
export function showKeuangan() {
    return axios.get(KEUANGAN_URL)
        .then(response => response.data.data);

}

