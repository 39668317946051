import React from 'react'
import DashboardPage from '../../modules/cassier/DashboardPage'

const Dashboard = () => {
    return (
        <div>
            <DashboardPage></DashboardPage>
        </div>
    )
}

export default Dashboard